import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import baseUrl from '../../config/baseUrl';
import toast, {Toaster} from 'react-hot-toast';

function Admin() {
  const [linkType, setLinkType] = useState('');
  const [fixedPrice, setFixedPrice] = useState(0);
  const [subscriptionPrice, setSubscriptionPrice] = useState(0);
  const [generatedLink, setGeneratedLink] = useState('');
  const [id, setId] = useState(uuidv4().substring(0, 6));

  const handleFixedPriceChange = (event) => {
    if (linkType === '') {
      toast.error('Please Select A Link Type Before Price Input.');
    }
    setId(uuidv4().substring(0, 6));
    setFixedPrice(Number(event.target.value));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let route = '';
      console.log(linkType);
      if (linkType === 'reparation') {
        route = `reparation/${id}/${fixedPrice}`;
      } else if (linkType === 'maintenanceWithoutAccommodation') {
        route = `maintenance/${id}/${fixedPrice}/${subscriptionPrice}`;
      } else if (linkType === 'maintenanceWithAccommodation') {
        route = `maintenance-et-hebergement/${id}/${fixedPrice}/${subscriptionPrice}`;
      }

      const generatedLink = `${baseUrl.url}${route}`;
      setGeneratedLink(generatedLink);
      
      setLinkType('');
      setId('');
      setFixedPrice(0);
      setSubscriptionPrice(0);

      toast.success('Link Generated Successfully! Please give this link to Client.')
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <>
      <Toaster
        position="top-center"
        containerStyle={{
          zIndex:
            "999",
        }}
      />
      <div className="container-fluid mt-4 text-center">
        <h2 className="mb-4">Generate a payment link to a client with stripe API</h2>
        <div className="row admin-class p-5">
          <div className="col-md-4">
            <div className="mb-4">
              <h5 className="font-inter-bold">What type of link do you like:</h5>
              <div className="form-check">
                <input
                  type="radio"
                  role='button'
                  className="form-check-input"
                  name='linkType'
                  checked={linkType === 'reparation'}
                  onChange={() => {
                    setLinkType('reparation');
                  }}
                />
                <label className="form-check-label">Fixed price (réparation simple)</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  role='button'
                  className="form-check-input"
                  name='linkType'
                  checked={linkType === 'maintenanceWithoutAccommodation'}
                  onChange={() => {
                    setLinkType('maintenanceWithoutAccommodation');
                  }}
                />
                <label className="form-check-label">Subscription + Fixed price (sans hébergement)</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  role='button'
                  className="form-check-input"
                  name='linkType'
                  checked={linkType === 'maintenanceWithAccommodation'}
                  onChange={() => {
                    setLinkType('maintenanceWithAccommodation');
                  }}
                />
                <label className="form-check-label">Subscription + Fixed price (avec hébergement)</label>
              </div>
            </div>
            <div className="mb-4">
              <h5 className="font-inter-bold">What is the fixed price in euros?</h5>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control col-auto"
                  placeholder={fixedPrice === 0 ? 'Enter Fixed Price' : ""}
                  value={fixedPrice !== 0 ? fixedPrice : ''}
                  onChange={(e) => handleFixedPriceChange(e)}
                  style={{ 'WebkitAppearance': 'none' }}
                />
                <div className="input-group-append">
                  <span className="input-group-text">.00</span>
                </div>
              </div>
            </div>
            {['maintenanceWithAccommodation', 'maintenanceWithoutAccommodation'].includes(linkType) && (
              <div className="mb-4">
                <h5 className="font-inter-bold">What is the subscription price in euros? </h5>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control col-auto"
                    placeholder={subscriptionPrice === 0 ? 'Enter Subscription Price' : ''}
                    value={subscriptionPrice !== 0 ? subscriptionPrice : ''}
                    onChange={(e) => setSubscriptionPrice(Number(e.target.value))}
                    style={{ 'WebkitAppearance': 'none' }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">.00</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="col-2 mb-4">
                <button type="submit" className="btn btn-primary">Generate link</button>
              </div>
              <div className='d-flex'>
                <div className="pe-2">
                  <p> Please give this link to the client:</p>
                </div>
                <div className='px-2'>
                  {generatedLink && (
                    <a
                      href={generatedLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>
                        {generatedLink}
                      </strong>
                    </a>
                  )}
                </div>
              </div>

            </form>


          </div>
        </div>
      </div>
    </>

  );
}

export default Admin;
