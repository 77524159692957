import React, { useState } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import toast, {Toaster} from 'react-hot-toast';
import Cookies from 'universal-cookie';

const InvoiceForm = () => {
  
  const cookies = new Cookies();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [amountPaid, setAmountPaid] = useState(cookies.get('amountPaid'));
  const [formData, setFormData] = useState({
    companyName: '',
    emailAddress: '',
    companyAddress: '',
    tva: '',
    amountPaid: amountPaid,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    console.log(formData);
    try {
      
      let resp = await axios.post('https://wordpress-pirate-backend-de88fe3b557a.herokuapp.com/generateInvoice', formData);

      if (resp.data.status) {
        toast.success(resp.data.message);
        let filepath = resp.data.filePath.split('/')[resp.data.filePath.split('/').length - 1];
        window.open(`https://wordpress-pirate-backend-de88fe3b557a.herokuapp.com/invoices/${filepath}`, '_blank');
        setFormData({
          companyName: '',
          emailAddress: '',
          companyAddress: '',
          tva: '', 
        })
        setFormSubmitted(false);
        window.location.reload();
      } else {
        toast.error(resp.data.message);
        setFormSubmitted(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = () => {
    console.log(formData); 
  };

  return (
    <div>
       <Toaster
        position="top-center"
        containerStyle={{
          zIndex:
            "999",
        }}
      />
      <Header headerStyle={false} headerFor={"invoice"}/> 
      <div className="container">
        <div className='row'>
          <div className="form-wrapper invoice-form__wrapper col-md-6 col-sm-12">
            <Form onSubmit={handleSubmit} className='text-start' id='invoice-form'>
              <FormGroup>
                <Label htmlFor="companyName" className="form-label">
                  Nom de votre société / Raison social
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Votre nom"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="emailAddress" className="form-label">
                  Adresse email
                </Label>
                <Input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  name="emailAddress"
                  value={formData.emailAddress}
                  onChange={handleChange}
                  placeholder="Adresse email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="address" className="form-label">
                  Adresse complète de votre société
                </Label>
                <Input
                  type='textarea'
                  rows="5"
                  className="form-control"
                  id="companyAddress"
                  name="companyAddress"
                  value={formData.companyAddress}
                  onChange={handleChange}
                  placeholder="Adresse de votre site:"
                  required
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="tva" className="form-label">
                  TVA Intracommunautaire
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  style={{ textTransform: 'uppercase' }}
                  id="tva"
                  name="tva"
                  value={formData.tva}
                  onChange={handleChange}
                  placeholder="TVA Intracommunautaire(FRXXXXXXX)"
                  required
                />
              </FormGroup>
              <FormGroup>
              <Button type="submit" className="btn primaryBtn mt-4">
                {
                  formSubmitted ? `Please Wait!` : `TELECHARGER MA FACTURE`
                }
              </Button>
              </FormGroup>
            </Form>
          </div>
          <div className="col-md-6 form-wrapper">
            <h2 className='text-uppercase dark-blue fw-bolder text-center'>MERCI POUR VOTRE PAIEMENT</h2>
            <img src={require('../../assets/images/thank-banner.png')} alt="thanksimage" className='object-fit-cover h-75 mw-100'/>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default InvoiceForm;

