import Header from '../../components/Header';
import Cookies from 'universal-cookie';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Link } from 'react-router-dom';
const baseURL = "http://192.168.2.42:5000/invoice"
const Thankyou = (props) => {
    const [companyName, setCompanyName] = useState("");
    const [vatNumber, setVatNumber] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");

    const [invoiceId, setInvoiceId] = useState("");
    const cookies = new Cookies();

    const handleSubmit = (event) => {
        
    }
    const handleClick= (event) => {
        event.preventDefault();
        let customerId = cookies.get("customerId")
        console.log(customerId, "customerId");

        axios.post(baseURL, {
            customerId: customerId,            
        }).then((res) => {
            
           
            return          
            
        }).then((data) => {
            if (data.error) {
                
            } else {
                
            }
            console.log(data, "data");
        })
    }
    useEffect(() => {
        setInvoiceId(cookies.get("invoiceId"));
        
    });
    return(
        <div>
            <Header headerStyle={props.headerStyle} />
            <div className="main-container ">
                <div className="content-wrapper">
                    <h1 className='text-uppercase dark-blue fw-bold text-center'>THANK YOU FOR YOUR PAYMENT</h1>
                    <img className="pt-5" src={require('../../assets/images/thank-banner.png')} alt="" />
                </div>
            </div>
            <div className="footer-wrapper">
                <div className="main-container " id="invoice-form">
                    <div className='form-wrapper invoice-form__wrapper'>
                        <div className="form-heading"><h2 className='text-uppercase white'>Generate your invoice</h2></div>
                        <Form className='text-start' id="invoice-form" onSubmit={handleSubmit}>
                            <Row>
                                <Col col="6">
                                    <FormGroup>
                                        <Label className='form-label white' for="companyName">Company name</Label>
                                        <Input id='companyName' name="companyName" placeholder='Company Name' onChange={(e) => setCompanyName(e.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col col="6">
                                    <FormGroup>
                                        <Label className='form-label white' for="vatNumber"> Vat Number (Optional)</Label>
                                        <Input id="vatNumber" name="vatNumber" placeholder='Vat Number' />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label className='form-label white' for="companyAddress">Full Company Address</Label>
                                <Input id="companyAddress" name="companyAddress" placeholder="Full company Address" />
                            </FormGroup>
                            <FormGroup className='text-center'>
                                <a href={ invoiceId }  className="primaryBtn white-btn" >GENERATE PDF</a>
                                
                            </FormGroup>
                            
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thankyou;