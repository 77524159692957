import React, { useState } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap'

const faqContent = [
    {
        header: 'Quel est la différence entre l’intervention simple et l’assurance ?',
        content: <p>Pour l’intervention simple nous vous rendons un site propre et fonctionnel, une fois notre prestation livrée notre service s’arrête.<br /> Pour l’assurance en plus de réparer votre site, nous le protégeons contre les piratages et bugs, nous vous assistons à le migrer si nécessaire, nous assurons les sauvegardes sur une plateforme externe et nous vous proposons un support gratuit en cas de problèmes.</p>
    },
    {
        header: 'Qu’est ce qui se passe après mon paiement ?',
        content: <p>Nous allons vous demander par mail ou par téléphone de nous transmettre les accès serveurs et WordPress de votre site. <br />Si vous ne les avez pas, nous allons vous assister à les retrouver. <br /> Nous conservons vos accès de façon ultra sécurisé et protocolisé pour des raisons de sécurité. </p>
    },
    {
        header: 'Et si ça se passe mal ou que je ne suis pas satisfait ?',
        content: <p>C’est simple nous remboursons la prestation depuis Stripe (carte bancaire) directement sans conditions particulières.</p>
    }
];

const FaqAccordion = (props) => {

    const [open, setOpen] = useState("");
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }
    
    return (
        <div className="footer-wrapper">
            <div className="main-container ">
                <h2 className="faq-heading text-uppercase text-white">Questions fréquentes & Ressources</h2>
                <p className="faq-subheading text-white">Vous avez une question sur nos services ? Consultez la liste de nos questions les plus fréquemment posées.</p>
                <Accordion flush open={open} toggle={toggle}>
                    { faqContent.map((item, index) => {
                        return (
                            <AccordionItem
                                key={index}
                            >
                                <AccordionHeader targetId={ index.toString() }>{ item.header }</AccordionHeader>
                                <AccordionBody accordionId={ index.toString() }>{ item.content }</AccordionBody>
                            </AccordionItem>
                        );    
                    })}
                </Accordion>
            </div>
        </div>
    );
}

export default FaqAccordion;