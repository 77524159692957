import React from "react";

const IconText = (props) => {
    return (
        <li className="icon-text d-flex align-items-center" >
            <span className="pe-2 d-flex"><img src={require('../../assets/images/checked-icon.svg').default} alt=""  /></span>
            <p className="mb-0">{props.iconContent }</p>
        </li>
    );
}

export default IconText;