const Header = (props) => {    
    console.log(props);
    return (
        <header className="header">
            <div className={ 'flex-column justify-content-center' + ' header-wrapper d-flex ' }>
                <div className="logo">
                    <img src={require('../../assets/images/header-logo.png')} alt="" className='object-fit-cover mw-100 headerImage'/>
                </div>
                {  props.headerStyle && props.headerFor !== 'invoice' ? 
                        <div className="header-text">
                            <p>Service de réparation de site internet</p>
                            <p>Piratages & Bugs</p>
                        </div>
                        :
                        <div className="header-text">
                            <h1 className='facture-header-text'>
                                DEMANDER MA FACTURE
                            </h1>
                        </div>
                }
                
            </div>
        </header>
    );
}

export default Header;