import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import IconText from '../../components/IconText'
import Header from '../../components/Header';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import PhoneInput from 'react-phone-number-input/input';
import { Link } from 'react-router-dom';
// import { Elements } from '@stripe/react-stripe-js';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import FaqAccordion from '../../components/FaqAccordion';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const iconTextContent = [
    "Support et livraison par des dévelppeurs/experts seniors 100% français",
    "Aucun abonnement",
    "Satisfait ou remboursé",
    "Nettoyage complet de votre site",
    "Réparation de bugs sur votre site",
    "Livraison en 48h "]



const baseURL = "https://wordpress-pirate-backend-de88fe3b557a.herokuapp.com/reparation"

const Reparation = (props) => {

    const seoInfo = 
    `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Qu'est-ce que votre offre de réparation ?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Notre service de réparation est destiné aux propriétaires de sites WordPress ayant subi une attaque ou un piratage. Nous proposons une intervention professionnelle, efficace et transparente pour restaurer la sécurité et la fonctionnalité de votre site."
          }
        },
        {
          "@type": "Question",
          "name": "Quel est le tarif de votre service de réparation ?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Nous proposons un tarif fixe de 350 € TTC pour la réparation de votre site WordPress. En cas d'échec, nous nous engageons à vous rembourser intégralement."
          }
        },
        {
          "@type": "Question",
          "name": "Comment fonctionne le processus de réparation ?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Après avoir effectué le paiement, vous recevrez un formulaire sécurisé pour nous transmettre vos informations d'accès. Notre équipe d'experts commencera alors immédiatement la réparation. Nous vous tiendrons informé à chaque étape du processus."
          }
        },
        {
          "@type": "Question",
          "name": "Comment puis-je vous contacter en cas de besoin ?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "N'hésitez pas à nous contacter au +33 9 77 19 62 02 (assistance technique) ou par e-mail à contact@wpsolution.io pour toute question ou assistance supplémentaire."
          }
        }
      ]
    }`
    
      

    const navigate = useNavigate();

    const { id, fp } = useParams();

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    // const [clientSecret, setClientSecret] = useState("");

    // Form data
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [note, setNote] = useState("");
    const [fixedPrice, setFixedPrice] = useState(0);

    const stripe = useStripe();
    const elements = useElements();

    const cookies = new Cookies();

    useEffect(() => {
        setFixedPrice(fp);
        if (!fp) {
            setFixedPrice(350);
        }
    }, [fp])

    const handleChange = async (event) => {
        // Listen for changes in the CardElement and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    async function confirmpayment(clientSecret) {
        return await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });
    }

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        
        axios.post(baseURL, {
            name: name,
            email: email,
            phone: phone,
            address: address,
            note: note,
            amount: fixedPrice
        }).then((res) => {

            console.log(res.data);
            setProcessing(true);
            cookies.set('customerId', res.data.customerId, { path: '/' });
            cookies.set('amountPaid', fixedPrice, {path: '/'});
            const payload = confirmpayment(res.data.client_secret)

            console.log(payload, "payload");
            return payload

        }).then((data) => {
            if (data.error) {
                console.log(data.error);
                console.log("failed")
                // setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
            } else {
                console.log("success")
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                navigate("/invoice");
            }
            console.log(data, "data");
        })
    };

    return (
        <div>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Réparation de Site WordPress | Service Rapide et Fiable - WPSolution</title>
                <meta name='title' content='Réparation de Site WordPress | Service Rapide et Fiable - WPSolution' />
                <meta name="description" content="Découvrez notre service de réparation professionnel pour les propriétaires de sites WordPress ayant subi une attaque ou un piratage. Tarif fixe de 350 € TTC, avec garantie de remboursement en cas d'échec. Restauration en 48h et communication transparente à chaque étape. Contactez-nous dès maintenant au +33 9 77 19 62 02." />
                <script type='application/ld+json'>{seoInfo}</script>
            </Helmet>
            <Header headerStyle={true} />
            <div className="main-container form-wrapper">
                <div className='row'>
                    <div className="col-md-6 col-sm-12">
                        <div className="text-start">
                            <h2 className="text-uppercase dark-blue fw-bold">Offre de Réparation de Site  Piraté : </h2>
                            <h2 className="text-uppercase dark-blue fw-bold">Votre sécurité, notre priorité</h2>

                            <h3 className="fw-bold mt-4">Qu’est-ce que notre offre de réparation ?</h3>
                            <p>
                                Notre service est destiné aux propriétaires de sites WordPress qui ont  subi une attaque ou un piratage. Nous proposons une intervention professionnelle, efficace et transparente pour restaurer la sécurité et la fonctionnalité de votre site.
                            </p>

                            <h3 className="fw-bold mt-3">Tarif et Garantie :</h3>
                            <p>
                                Pour un tarif fixe de {fixedPrice}€ TTC, nous nous engageons à réparer et vous rendre votre site fonctionnel sous 48h. <br></br>
                                En cas d’echec, nous nous engageons à vous <strong>rembourser intégralement.</strong>
                            </p>

                            <h3 className='fw-bold mt-3'>Comment cela fonctionne-t-il ?</h3>
                            <ol type='1'>
                                <li>
                                    <p>Suite à votre règlement, vous recevrez un formulaire sécurisé pour nous transmettre vos informations d'accès.</p>
                                </li>
                                <li>
                                    <p>Une fois ces informations reçues, notre équipe d'experts commencera immédiatement la réparation.</p>
                                </li>
                                <li>
                                    <p>Pendant toute l'intervention, nous assurons une communication transparente, vous tenant informé de chaque étape et de nos avancées.</p>
                                </li>
                            </ol>

                            <h3 className='fw-bold mt-3'>Contactez-nous :</h3>
                            <p>N'hésitez pas à nous contacter au +33 9 77 19 62 02 (assistance technique) ou par e-mail à <u>contact@wpsolution.io.</u></p>

                            <ul className="icon-list mt-4">
                                {iconTextContent.map((item, index) => {
                                    return <IconText key={index} iconContent={item} />
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="reparation-form__wrapper">
                            <div className="form-heading"><h2 className='text-uppercase dark-blue'>📝 Payer ma prestation</h2></div>
                            <Form className='text-start' id="reparation-form" onSubmit={handleSubmit} >
                                <FormGroup>
                                    <Label className="form-lable" for="fullName">Votre Nom & Prénom</Label>
                                    <Input id="fullName" name="name" placeholder="Votre nom" onChange={(e) => setName(e.target.value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Adresse email</Label>
                                    <Input id="email" type="email" name="email" placeholder="Adresse email" onChange={(e) => setEmail(e.target.value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="phone">Numéro de téléphone</Label>
                                    <PhoneInput id="phone" className="form-control" name="phone" placeholder="Numéro de téléphone" onChange={(e) => setPhone(e)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="address">URL de votre site</Label>
                                    <Input id="address" name="address" placeholder="Adresse de votre site" onChange={(e) => setAddress(e.target.value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="card">Votre carte bancaire</Label>
                                    <CardElement
                                        id="card-element"
                                        options={{}}
                                        name="card"
                                        onChange={handleChange}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Label for="note">Note pour le développeur (optionnel)</Label>
                                    <Input type="textarea" id="note" name="note" rows="5" placeholder="Note pour le développeur" onChange={(e) => setNote(e.target.value)} />
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="checkbox" required />
                                    <Label check>
                                        J’accepte les <Link to="https://docs.google.com/document/d/1LD6BssZDKJbEfFH3SbZ0nPWDDnsKQ0DbKweX1M_vufs/" target='_blank' style={{ textDecoration: 'underline' }}>conditions de ventes du service</Link>
                                    </Label>
                                </FormGroup>

                                {/* <Button className="primaryBtn full-width" disabled={processing || disabled || succeeded} >PAY 250 €</Button> */}
                                <Button className="primaryBtn full-width" disabled={processing || disabled || succeeded}>PAYER {fixedPrice} €</Button>

                                {/* Show any error that happens when processing the payment */}
                                {error && (
                                    <div className="card-error" role="alert">{error}</div>
                                )}

                            </Form>

                        </div>
                    </div>
                </div>
            </div>
            <FaqAccordion />
        </div>
    );
}

export default Reparation