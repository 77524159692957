import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import IconText from '../../components/IconText'
import Header from '../../components/Header';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PhoneInput from 'react-phone-number-input/input';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from "react-router-dom";
import FaqAccordion from '../../components/FaqAccordion';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';

const iconTextContent = [
    "Support et livraison par des devs/epxerts séniors 100% français",
    "Aucun abonnement",
    "Satisfait ou remboursé",
    "Nettoyage complet de votre site",
    "Réparation des bugs sur votre site",
    "Livraison en 48h"]



const baseURL = "https://wordpress-pirate-backend-de88fe3b557a.herokuapp.com/assurance";

const Assurance = (props) => {

    const seoInfo = `{
  "@context": "http://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Qu'est-ce que votre offre de maintenance anti-piratage ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Notre service est destiné aux propriétaires de sites WordPress qui ont subi une attaque ou un piratage. Nous proposons une intervention professionnelle, efficace et transparente pour restaurer la sécurité et la fonctionnalité de votre site. La maintenance est une prestation continue destinée à renforcer et préserver la sécurité de votre site WordPress. Au-delà d'une simple maintenance, nous veillons à ce que votre site reste à jour, sécurisé et libre de tout bug."
      }
    },
    {
      "@type": "Question",
      "name": "Quel est le tarif de votre service de maintenance anti-piratage ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pour un tarif fixe de 350 € TTC, nous nous engageons à réparer et vous rendre votre site fonctionnel sous 48 heures. En cas d'échec, nous nous engageons à vous rembourser intégralement. De plus, nous proposons un abonnement mensuel à 50 € TTC pour une protection continue de votre site."
      }
    },
    {
      "@type": "Question",
      "name": "Comment fonctionne votre service de maintenance anti-piratage ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Suite à votre règlement, vous recevrez un formulaire sécurisé pour nous transmettre vos informations d'accès. Une fois ces informations reçues, notre équipe d'experts commencera immédiatement la réparation. Pendant toute l'intervention, nous assurons une communication transparente, vous tenant informé de chaque étape et de nos avancées. Une fois réparé et sécurisé, nous installons et configurons un antivirus robuste pour offrir une protection continue contre les menaces extérieures. Votre site bénéficiera de mises à jour régulières, tant au niveau de WordPress que des plugins associés. En plus des mises à jour, nous assurons des sauvegardes périodiques de votre site, garantissant ainsi une restauration rapide en cas de problème."
      }
    },
    {
      "@type": "Question",
      "name": "Comment puis-je vous contacter pour utiliser votre service ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "N'hésitez pas à nous contacter au +33 9 77 19 62 02 pour obtenir une assistance technique ou par e-mail à contact@wpsolution.io. Nous sommes là pour répondre à toutes vos questions et vous aider à sécuriser votre site WordPress."
      }
    }
  ]
}`

    const navigate = useNavigate();

    const { id, fp, sp } = useParams();

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState("");

    // Form data
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [note, setNote] = useState("");
    const [pay, setPay] = useState('monthly');
    const [fixedPrice, setFixedPrice] = useState(0);
    const [subscriptionPrice, setSubscriptionPrice] = useState(0);
    const [generatedByAdmin, setGeneratedByAdmin] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const cookies = new Cookies();

    const handleChange = async (event) => {
        // Listen for changes in the CardElement and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    useEffect(() => {
        setGeneratedByAdmin(id ? true : false);
        setFixedPrice(fp);
        setSubscriptionPrice(sp);
        if (!fp && !sp) {
            setFixedPrice(350);
            setSubscriptionPrice(50);
        }
    }, [])

    async function confirmpayment(clientSecret) {
        return await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });
    }
    const handleSubmit = async (ev) => {
        ev.preventDefault();
        axios.post(baseURL, {
            name: name,
            email: email,
            phone: phone,
            address: address,
            note: note,
            payMode: 'assurance',
            fixedPriceAmount: fixedPrice,
            subscriptionPriceAmount: subscriptionPrice,
            generatedByAdmin: generatedByAdmin
        }).then((res) => {
            console.log(res.data, "data");
            setClientSecret(res.data);
            cookies.set('invoiceId', res.data.invoiceId, { path: '/' });
            cookies.set('amountPaid', `${fixedPrice}` + '+' + `${subscriptionPrice}`, { path: '/' });
            setProcessing(true);
            const payload = confirmpayment(res.data.clientSecret);
            return payload

        }).then((data) => {
            if (data.error) {
                console.log("failed")
                // setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
            } else {
                console.log("success")
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                navigate("/invoice");
            }
            console.log(data, "data");
        })
    };


    return (
        <div>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Offre de Maintenance Anti-Piratage pour Sites WordPress</title>
                <meta name='title' content='Offre de Maintenance Anti-Piratage pour Sites WordPress' />
                <meta name="description" content="Découvrez notre offre de maintenance anti-piratage pour les propriétaires de sites WordPress. Restaurez la sécurité et la fonctionnalité de votre site avec notre intervention professionnelle et transparente. Tarif fixe de 350 € TTC avec garantie de remboursement en cas d'échec. Protégez votre site avec notre service de maintenance continue à 50€ TTC par mois. Contactez-nous au +33 9 77 19 62 02 ou par e-mail à contact@wpsolution.io." />
                <script type='application/ld+json'>{seoInfo}</script>
            </Helmet>
            <Header headerStyle={props.headerStyle} />
            <div className="main-container form-wrapper">
                <div className='row'>
                    <div className="col-md-6 col-sm-12">
                        <div className="text-start">
                            <h2 className="text-uppercase dark-blue fw-bold">Offre de Réparation de Site  Piraté : </h2>
                            <h2 className="text-uppercase dark-blue fw-bold">Votre sécurité, notre priorité</h2>

                            <h3 className="fw-bold mt-4">Qu’est-ce que notre offre de maintenance anti-piratage ?</h3>
                            <p>Notre service est destiné aux propriétaires de sites WordPress qui ont  subi une attaque ou un piratage. Nous proposons une intervention professionnelle, efficace et transparente pour restaurer la sécurité et la fonctionnalité de votre site. <br></br>
                                La maintenance est une prestation continue destinée à renforcer et préserver la sécurité de votre site WordPress. Au-delà d'une simple maintenance, nous veillons à ce que votre site reste à jour, sécurisé et libre de tout bug.
                            </p>

                            <h3 className='fw-bold mt-3'>
                                Tarif et Garantie :
                            </h3>
                            <p>
                                Pour un tarif fixe de {fixedPrice}€ TTC, nous nous engageons à réparer et vous rendre votre site fonctionnel sous 48h. <br></br>
                                En cas d’echec, nous nous engageons à vous <strong>rembourser intégralement. </strong> <br></br>
                                Pour un montant de {subscriptionPrice}€ TTC par mois, vous bénéficiez d'une protection complète et régulière, et protégé par un antivirus performant. <br></br>
                                Investir dans la maintenance, c'est assurer la longévité et la sécurité de votre site sur le long terme.<br></br>
                                De plus, en cas de nouvelle attaque après notre intervention, nous intervenons sans coût supplémentaire pour vous.
                            </p>

                            <h3 className='fw-bold mt-3'>
                                Comment cela fonctionne-t-il ?
                            </h3>
                            <ol type='1'>
                                <li>
                                    <p>Suite à votre règlement, vous recevrez un formulaire sécurisé pour nous transmettre vos informations d'accès.</p>
                                </li>
                                <li>
                                    <p>
                                        Une fois ces informations reçues, notre équipe d'experts commencera immédiatement la réparation.
                                    </p>
                                </li>
                                <li>
                                    <p>Pendant toute l'intervention, nous assurons une communication transparente, vous tenant informé de chaque étape et de nos avancées.</p>
                                </li>
                                <li>
                                    <p>Une fois réparé et sécurisé, nous installons et configurons un antivirus robuste pour offrir une protection continue contre les menaces extérieure. Votre site bénéficiera de mises à jour régulières, tant au niveau de WordPress que des plugins associés. <br></br>
                                        En plus des mises à jour, nous assurons des sauvegardes périodiques de votre site, garantissant ainsi une restauration rapide en cas de problème.
                                    </p>
                                </li>
                            </ol>

                            <h3 className='fw-bold mt-3'>
                                Contactez-nous :
                            </h3>
                            <p>N'hésitez pas à nous contacter au +33 9 77 19 62 02 (assistance technique) ou par e-mail à <u>contact@wpsolution.io.</u></p>

                            <ul className="icon-list mt-4">
                                {iconTextContent.map((item, index) => {
                                    return <IconText key={index} iconContent={item} />
                                })}
                            </ul>

                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="reparation-form__wrapper">
                            <div className="form-heading"><h2 className='text-uppercase dark-blue fw-bold'>📝 Payer ma prestation</h2></div>
                            <Form className='text-start' id="reparation-form" onSubmit={handleSubmit} >
                                <FormGroup>
                                    <Label className="form-lable" for="fullName">Votre Nom & Prénom</Label>
                                    <Input id="fullName" name="name" placeholder="Votre nom" onChange={(e) => setName(e.target.value)} required />
                                </FormGroup>
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="email">Adresse email</Label>
                                            <Input id="email" type="email" name="email" placeholder="Adresse email" onChange={(e) => setEmail(e.target.value)} required />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <Label for="phone">Numéro de téléphone</Label>
                                            <PhoneInput id="phone" className="form-control" name="phone" placeholder="Numéro de téléphone" onChange={(e) => setPhone(e)} required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="address">URL de votre site</Label>
                                    <Input id="address" name="address" placeholder="Adresse de votre site" onChange={(e) => setAddress(e.target.value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="card">Votre carte bancaire</Label>
                                    <CardElement
                                        id="card-element"
                                        options={{}}
                                        name="card"
                                        onChange={handleChange}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Label for="note">Note pour le développeur (optionnel)</Label>
                                    <Input type="textarea" id="note" name="note" rows="5" placeholder="Note pour le développeur" onChange={(e) => setNote(e.target.value)} />
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="checkbox" required />
                                    <Label check>
                                        J’accepte les <Link to="https://docs.google.com/document/d/13hcI-3K2FNxjptksU9Pq0p7C8D7P-qeO7XhieoRfejo/" target='_blank' style={{ textDecoration: 'underline' }}>conditions de ventes du service</Link>
                                    </Label>
                                </FormGroup>

                                <Button className="primaryBtn full-width" disabled={processing || disabled || succeeded} >
                                    {
                                        pay == "monthly" ? `${fixedPrice} € setup fixe + ${subscriptionPrice} € par mois` : `${Number(fixedPrice) + Number(subscriptionPrice)} € par an`
                                    }
                                </Button >

                                {/* Show any error that happens when processing the payment */}
                                {error && (
                                    <div className="card-error" role="alert">{error}</div>
                                )}

                            </Form>

                        </div>
                    </div>
                </div>
            </div>
            <FaqAccordion />
        </div>
    );
}

export default Assurance;