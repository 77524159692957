import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Assurance from '../pages/Assurance';
import AssuranceAvecHebergement from '../pages/AssuranceAvecHebergement';
import Reparation from '../pages/Reparation';
import Thankyou from '../pages/Thankyou';
import Admin from '../pages/Admin';
import InvoiceForm from '../pages/Invoice';

const Layout = (props) => {

   const location = useLocation();
   const navigate = useNavigate();

   React.useEffect(() => {
    if (location.pathname === '/') {
        navigate("/reparation");
    }
   }, [])
    
   return (
    <div className="main-wrapper">
        <Routes>
            <Route path='/admin' element={<Admin />}/> 
            <Route path='/invoice' element={<InvoiceForm />} headerStyle={true}/> 
            <Route path="/maintenance/:id/:fp/:sp" element={<Assurance headerStyle={true} />} />
            <Route path="/reparation/:id/:fp" element={<Reparation headerStyle={true} />} />
            <Route path="/reparation" element={<Reparation headerStyle={true} />} />
            <Route path="/maintenance-et-hebergement/:id/:fp/:sp" element={<AssuranceAvecHebergement headerStyle={true} />} />
            <Route path="/maintenance-et-hebergement" element={<AssuranceAvecHebergement headerStyle={true} />} />
            <Route path="/maintenance" element={<Assurance headerStyle={true} />} />
            <Route path="/thankyou" element={<Thankyou headerStyle={false} />} />
        </Routes>
    </div>
   );
}

export default Layout;